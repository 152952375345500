import { decodeDpm, ViewableModel } from "@bbai-dartmouth/dartmouth-model-viewer-lib"
import { NRRDLoader } from './NRRDLoader'
import camelcaseKeys from 'camelcase-keys'
import { ScanResult, ScanResultFailure } from "../models/ScanResult"
import { lookupConclusionCategory } from "../machines/fileApplicationMachine"

type ViewableModelLoader = (data: Buffer | ArrayBuffer) => Promise<ViewableModel>

const loadFileNRRD: ViewableModelLoader = async (data): Promise<ViewableModel> => {
    try {
        const loader = new NRRDLoader()
        const volume = loader.parse(data) as any
        const width: number = volume.xLength
        const height: number = volume.yLength
        const depth: number = volume.zLength
        //@ts-ignore
        const scale: [number, number, number] = volume?.header?.spacings || [1, 1, 1]

        return {
            type: 'nrrd',
            model: volume,
            scaled: scale[0] !== 1,
            dimensions: {
                width,
                height,
                depth,
                scale,
            },
            metadata: {
                rescaleIntercept: -900,
                rescaleSlope: 100.0,
            },
        } as ViewableModel
    } catch (e) {
        throw new Error('failed to load file', { cause: e })
    }
}

const loadFileDRT: ViewableModelLoader = async (data) => {
    try {
        const arrayBuffer = data
        if (arrayBuffer.byteLength < 1) {
            throw new Error('invalid file length')
        }
        //@ts-ignore
        const dpm = decodeDpm(arrayBuffer)
        const width: number = dpm.metadata.width
        const height: number = dpm.metadata.height
        const depth: number = dpm.slices.count
        const scale: [number, number, number] = [
            dpm.metadata.pixelWidth,
            dpm.metadata.pixelHeight,
            dpm.metadata.pixelDepth,
        ]

        return {
            type: 'dpm',
            model: dpm,
            scaled: scale[0] !== 1,
            dimensions: {
                width,
                height,
                depth,
                scale,
            },
            metadata: {
                rescaleIntercept: dpm.metadata.rescaleIntercept,
                rescaleSlope: dpm.metadata.rescaleSlope,
            },
        } as ViewableModel
    } catch (e) {
        throw new Error('failed to load file', { cause: e })
    }
}

const cleanupResultFile = (_result: any, categories?: Record<string, string>) => {
    let resultOut: ScanResultFailure | ScanResult = {
        id: 'unknown',
        conclusions: []
    }

    try {
        //@ts-ignore
        let result = (_result?.data !== undefined && _result?.data?.id !== undefined)
            ? camelcaseKeys((_result as any).data, { deep: true })
            : camelcaseKeys(_result as any, { deep: true })
        resultOut = {
            ...result,
            //@ts-ignore
            conclusions: result?.conclusions?.map((conclusion) => ({
                ...conclusion,
                category: lookupConclusionCategory(conclusion.label, categories)
            })) ?? [],
        } as ScanResult
    } catch (e: any) {
        console.warn('[applicationMachine:loadFile]', 'Failed to load json file', e?.message ?? e)
        console.log('[applicationMachine:loadFile]', 'JSON', _result)
    }
    return resultOut
}

export {
    cleanupResultFile,
    loadFileDRT,
    loadFileNRRD,
}

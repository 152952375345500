import { ScanResult } from '../../models/ScanResult'
import { enqueueActions, EventObject, fromPromise, NonReducibleUnknown, PromiseActorLogic } from "xstate"
import { ApplicationBrowsePathInput, ApplicationBrowsePathResult, ApplicationContext, ApplicationLoadFileInput, ApplicationLoadStoreResult, ApplicationScanData, FileExtensionMatch, groupReportData, ReportSchemaRowExt, ZReportSchema } from "../fileApplicationMachine"
import { FileResult, StorageMode, StorageResponse, StoreStatusResponse } from "../../types"
import { loadFileDRT, loadFileNRRD } from '../../lib/scans'
import { getAuthHeaders, ProgressRefs } from './loaders'
import { config } from '../../config'

interface MachineLayerLoaders {
    getFolderContents: (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, auth: string | null) => Promise<FileResult[]>
    getStoreStatus: (protocol: StorageMode, store: string, auth: string | null) => Promise<StoreStatusResponse>
    selectFolder: (protocol: StorageMode, store: string, auth: string | null) => Promise<StorageResponse | null>
    loadBinaryFile: (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, progress?: ProgressRefs) => Promise<Buffer | ArrayBuffer>
    loadCSVFile: (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, progress?: ProgressRefs) => Promise<any[]>
    loadTextFile: (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, progress?: ProgressRefs) => Promise<string>
    loadJSONFile: (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, progress?: ProgressRefs) => Promise<ScanResult>

    loadScanResult: (params: ApplicationLoadFileInput) => Promise<ScanResult>
    loadApiResult: (params: ApplicationLoadFileInput) => Promise<ScanResult>
}



const fileResultToReport = (file: FileResult): ReportSchemaRowExt => ({
    file: "-",
    output: file.name,
    label: "LABEL",
    category: "CATEGORY",
    probability: 1,
    orientation: "0 270 180",
    image_file_name: "-",
    "date": "10/09/2024 14:22:27",
    "threats_detected": 1,
    "threats_reported": 1,
    "algo_time(in seconds)": 59.022,
    "exec_time(in seconds)": 60.214,
    scan_id: file.meta?.scan_id ?? 'unknown',
    provider_scan_id: "unknown",
    error: "-",
    rows: [
        0
    ],
    "result": file,
    "outputLocal": file.path,
})

interface MachineLayer {
    actors: Record<string, PromiseActorLogic<any, NonReducibleUnknown, EventObject>>
    actions: Record<string, any>
}

const layer: (loaders: MachineLayerLoaders) => MachineLayer = (loaders) => ({
    actions: {
        toggleFullscreen: enqueueActions<ApplicationContext, EventObject, any>(({ context, enqueue }) => {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen()
                enqueue.assign({
                    view: {
                        ...context.view,
                        fullscreen: true,
                    }
                })
            } else if (document.exitFullscreen) {
                document.exitFullscreen()
                enqueue.assign({
                    view: {
                        ...context.view,
                        fullscreen: false,
                    }
                })
            }
        }),
    },
    actors: {
        browsePath: fromPromise<ApplicationBrowsePathResult>(async (parameters: { input: any }) => {
            let { authRef, basePath: currentBasePath, path, report, project, protocol, store } = parameters.input as ApplicationBrowsePathInput
            if (protocol === 'api' as StorageMode) {

                const id = project!.id,
                    type = project!.selectMode,
                    status = project!.status
                if (type === 'none') {
                    return {
                        isMissingProject: true,
                        tree: [],
                    }
                }
                const batch_id = type === 'id'
                    ? id
                    : await fetch(`${config.apiServer}/data/batch?reference=${id}`, getAuthHeaders(authRef.current))
                        .then((result) =>
                            result.json())
                        .then(async (response) => {
                            if (!response.ok || response.data.total < 1) {
                                throw new Error('failed to find batch by reference')
                            }
                            return response.data.results[0].id
                        })
                const scanIds = await fetch(`${config.apiServer}/helpers/work/batch/${batch_id}/${status}`, getAuthHeaders(authRef.current))
                    .then((result) =>
                        result.json())
                    .then(async (response) => {
                        if (!response.ok) {
                            throw new Error('failed to load store list')
                        }
                        const scanFiles = await Promise.all(response.data.map(async ({ id: scanId, reference }: any) =>
                            fetch(`${config.apiServer}/data/scanConversion?scan_id=${scanId}`, getAuthHeaders(authRef.current)).then((result) =>
                                result.json())
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error('failed to load store list')
                                    }

                                    const smallest = (response.data.results.sort((a: any, b: any) => {
                                        const aScore = (a.compressed ? 10 : 0) + (a.downsampled ? 100 : 0)
                                        const bScore = (b.compressed ? 10 : 0) + (b.downsampled ? 100 : 0)
                                        return bScore - aScore
                                    }))?.[0]

                                    if (smallest) {
                                        smallest.source_uri = smallest.source_uri.replace('gs:/', 's3:/')
                                    }

                                    const [[, bucket, path, filename]] = smallest?.source_uri?.matchAll(/s3:\/\/([^\/]+)\/(.+)\/([^\/]+)$/g)

                                    return {
                                        ...smallest,
                                        bucket,
                                        path,
                                        filename,
                                        scan_reference: reference
                                    }
                                })
                        ))
                        return scanFiles
                    })
                if (scanIds.length < 1) {
                    return {
                        isEmptyProject: true,
                        tree: [],
                    }
                }
                let tree: FileResult[] = scanIds.map((scan) => ({
                    id: scan.scan_id,
                    ref: `${scan.path}/${scan.filename}`,
                    name: scan.scan_reference.replace(FileExtensionMatch, ''),
                    store: scan.bucket,
                    visible: true,
                    meta: {
                        api: true,
                        batch_id,
                        scan_id: scan.scan_id,
                        scan_conversion_id: scan.id,
                    },
                    size: 5019163,
                    type: 'file',
                    path: `/${scan.filename}`,
                    parent: '/',
                    protocol: 's3',
                } as FileResult))
                let path = ''
                console.log('SCAN IDS', scanIds)

                return {
                    loadInitial: true,
                    hasFiles: true,
                    report: tree.map(fileResultToReport),
                    tree,
                    isPartial: false,
                    path: path,
                    basePath: path,
                    handle: null,
                    categories: [],
                    projects: [],
                }
            }

            let partialSelection = path === undefined
            let handle: undefined | FileSystemDirectoryHandle
            let projects = []
            let categories: undefined | Record<string, string>
            if (partialSelection) {
                let folderResponse = await loaders.selectFolder(protocol, store!, authRef.current) ?? null
                if (folderResponse === null || (!folderResponse.partialSelection && folderResponse.directory === null)) {
                    throw new Error('no path selected')
                }
                if (!folderResponse.partialSelection && folderResponse.directory === undefined) {
                    throw new Error('no directory in response')
                }
                handle = folderResponse?.handle
                partialSelection = folderResponse.partialSelection
                path = folderResponse.partialSelection || folderResponse.directory === null
                    ? undefined
                    : folderResponse.directory
                projects = folderResponse.partialSelection
                    ? folderResponse.projects
                    : []
            }
            if (partialSelection) {
                return {
                    tree: [],
                    isPartial: true,
                    projects,
                }
            }

            const tree = await loaders.getFolderContents(protocol, store!, handle!, path!, authRef.current)
            if (report === undefined) {
                try {
                    const reportData = await loaders.loadCSVFile(protocol, store!, handle!, '/report.csv', tree, authRef.current).catch((e: any) => {
                        return undefined
                    })
                    const reportDataParsed = ZReportSchema.parse(reportData)
                    categories = reportDataParsed.reduce((list, item) => ({
                        ...list,
                        [item.label]: item.category,
                    }), {})
                    report = groupReportData(reportDataParsed, tree)
                } catch (e) {
                    console.error('failed to load csv', e)
                    report = undefined
                }
            }
            const hasFiles = (tree.length > 0)
            if (!hasFiles) {
                throw new Error(`No files found in path: '${protocol}:${store ?? ''}/${path}'`)
            }

            return {
                hasFiles: true,
                report,
                api: true,
                tree,
                isPartial: false,
                path: path,
                basePath: currentBasePath ?? path,
                handle,
                categories,
                projects,
            }
        }),
        loadStore: fromPromise<ApplicationLoadStoreResult>(async (parameters: { input: any }) => {
            const { store, authRef, protocol } = parameters.input as ApplicationBrowsePathInput
            // if (protocol === 'api' as StorageMode) {
                return {
                    ok: true,
                    store,
                }
            // }

            // const result = await fetch(`${config.apiServer}/stores/`, getAuthHeaders(authRef.current))
            //     .then((result) =>
            //         result.json())
            //     .then((response) => {
            //         if (!response.ok) {
            //             throw new Error('failed to load store list')
            //         }
            //         return response.data.map(({ bucket }: any) => bucket)
            //     })
            // console.debug('LOAD STORE', result)
            // if (result.includes(store)) {
            //     return {
            //         ok: true,
            //         store,
            //     }
            // }

            // return {
            //     stores: result,
            //     ok: false,
            // }
        }),
        loadFile: fromPromise<ApplicationScanData>(async (parameters: { input: any }) => {
            try {
                const { authRef, refs, store, file, report, categories, tree, handle } = parameters.input as ApplicationLoadFileInput
                const isNRRD = /\.nrrd$/.test(file.path)
                const protocol = file.protocol as StorageMode
                const result = file?.meta?.api
                    ? await loaders.loadApiResult(parameters.input)
                    : await loaders.loadScanResult(parameters.input)

                const data = await loaders.loadBinaryFile(protocol, store, handle, file.path, tree, authRef.current, refs)
                const viewable = isNRRD
                    ? await loadFileNRRD(data)
                    : await loadFileDRT(data)

                console.debug('MODEL DATA', result, file.path, viewable.dimensions, viewable.type, viewable.metadata)
                return {
                    report,
                    result,
                    viewable,
                }
            } catch (e) {
                throw e
            }
        })
    }
})

export default layer

type StorageMode = 's3' | 'gs' | 'google' | 'local' | 'api'
type FileResult = {
    name: string
    children: number
    visible: boolean
    meta: any
    parent: string
    path: string
    ref: string
    store: string | null
    type: 'folder'
    api?: boolean
    id?: string
    protocol: StorageMode
} | {
    name: string
    visible: boolean
    meta: any
    parent: string
    path: string
    store: string | null
    api?: boolean
    ref: string
    size: number
    type: 'file'
    id?: string
    protocol: StorageMode
}

export type {
    FileResult,
    StorageMode,
}

export const FileExtensionMatch = /\.(drt|nrrd)(\.br)?$/i
export const FullFileExtensionMatch = /(\.min)?\.(drt|nrrd)(\.br)?$/i
export const DicosFileExtensionMatch = /\.(dcs)$/i

export type StoreStatusResponse = {
    metadata: any
    ok: true
} | {
    stores: string[]
    ok: false
}

export type StorageResponse = {
    directory: string | null
    partialSelection: false
    handle?: FileSystemDirectoryHandle
} | {
    partialSelection: true
    projects: any[]
    handle?: FileSystemDirectoryHandle
}


const getUserProfile = (token: string): UserProfile => {
    const decodedToken: any = decodeToken(token)
    return decodedToken as UserProfile
}
const { jwtDecode } = require('jwt-decode')

const decodeToken = (token: string): any => {
    if (token) {
        return jwtDecode(token, { header: false })
    }
}

enum UserRoles {
    Admin = 'Admin',
    Labeller = 'Labeller',
    LabellerAdmin = 'LabellerAdmin',
    ModelTrainer = 'ModelTrainer',
}

interface UserProfile {
    name: string
    email: string
    family_name: string
    given_name: string
    preferred_username: string
    roles: string[]
    sub: string
    // sid: string
    exp: number
    iat: number
    email_verified: string
}

export type {
    UserProfile,
}

export {
    UserRoles,
    getUserProfile,
}

import React, { PropsWithChildren, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { FileApplicationContainer, ProjectApplicationContainer, useApplication } from '../containers/FileApplicationContainer'
import { DataPathSelect, RemotePathSelect } from '../components/RemoteSelect'
import { ModeContainer } from '../containers/ModeContainer'
import styles from './FileApplicationLayout.module.css'
import { ProjectSelection } from '../machines/fileApplicationMachine'
import { ApprovalStatus } from '../../models'


const ApplicationModals = () => {
    const { currentState } = useApplication()

    return <>
        {currentState === 'storeSelect' || currentState === 'pathSelect'
            ? <RemotePathSelect
                key={'storeSelector'}
            />
            : null}
        {currentState === 'dataPathSelect'
            ? <DataPathSelect
                key={'pathSelector'}
            />
            : null}
    </>
}

const FileApplicationLayout: React.FC<PropsWithChildren> = ({ children }) => {
    return <FileApplicationContainer
        key={'appContainer'}
    >
        <div
            key={'appLayout'}
            className={styles.appLayout}
        >
            <ApplicationModals />
            {children}
        </div>
    </FileApplicationContainer>
}


const ProjectApplicationLayout: React.FC<PropsWithChildren<{ mode: 'user' | 'admin' }>> = ({ children, mode }) => {
    const params = useParams()
    const selection = useMemo<ProjectSelection>(() => {
        const status = (mode === 'admin'
            ? params?.status ?? 'pending'
            : 'assigned') as ApprovalStatus

        if (params?.id) {
            return {
                key: `id:${params.id}:${params?.time ?? '0'}`,
                selectMode: 'id',
                id: params.id,
                status,
                mode,
            }
        }
        if (params?.reference) {
            return {
                key: `ref:${params.reference}:${params?.time ?? '0'}`,
                selectMode: 'reference',
                id: params.reference,
                status,
                mode,
            }
        }
        return {
            key: `none`,
            selectMode: 'none',
            mode,
            status,
        }
    }, [params, mode])

    return <ModeContainer
        title={mode === 'admin'
            ? 'admin'
            : 'bootcamp'}
        mode={mode}
    ><ProjectApplicationContainer
        key={`appContainer_${selection.key}`}
        selection={selection}
    >
            <div
                key={'appLayout'}
                className={styles.appLayout}
            >
                <ApplicationModals />
                {children}
            </div>
        </ProjectApplicationContainer></ModeContainer>
}

export {
    FileApplicationLayout,
    ProjectApplicationLayout,
}

type Config = {
    baseURI: string
    authority: string
    apiServer: string
    clientId: string
    scopes: string[]
    responseType: string
    isProduction: boolean
    isDevelopment: boolean
    isLocal: boolean
}

const LOCAL_API_SERVER = 'http://localhost:3001'
const LOCAL_CLIENT_SERVER = 'https://localhost:3000'

const DOMAIN_PRODUCTION = 'bootcamp.dartmouth-prod.cloud'
const DOMAIN_DEVELOPMENT = 'bootcamp.dartmouth-dev.cloud'

const SUBDOMAIN_OAUTH = 'keycloak'
const SUBDOMAIN_API = 'api'

const OAUTH_REALM = 'bootcamp'
const OAUTH_CLIENT_ID = 'bootcamp'

const IS_LOCAL: boolean = /https:\/\/localhost:/.test(window.location.toString())

const DOMAIN_BASE = window.location.hostname
const IS_PRODUCTION = Boolean(DOMAIN_BASE.toLowerCase() === DOMAIN_PRODUCTION)


export const config: Config = {
    authority: `https://${SUBDOMAIN_OAUTH}.dartmouth-prod.cloud/realms/${OAUTH_REALM}`,
    // IS_LOCAL
    //     ? `https://${SUBDOMAIN_OAUTH}.${DOMAIN_DEVELOPMENT}/realms/${OAUTH_REALM}`
    //     : `https://${SUBDOMAIN_OAUTH}.${DOMAIN_BASE}/realms/${OAUTH_REALM}`,
    clientId: OAUTH_CLIENT_ID,

    scopes: ['profile', 'email', 'openid', 'roles'],
    responseType: 'code',

    baseURI: IS_LOCAL
        ? LOCAL_CLIENT_SERVER
        : `https://${DOMAIN_BASE}`,
    apiServer: IS_LOCAL
        ? LOCAL_API_SERVER
        : `https://${SUBDOMAIN_API}.${DOMAIN_BASE}`,

    isProduction: IS_PRODUCTION,
    isDevelopment: !IS_PRODUCTION && !IS_LOCAL,
    isLocal: IS_LOCAL,
}

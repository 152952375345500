import { DartmouthIdent } from './DartmouthIdent'
import { BsFolder, BsSlashCircle, BsCardList, BsHouseDoor, BsSearch } from 'react-icons/bs'
import { SidebarMode } from '../machines/fileApplicationMachine'
import styles from './SidebarMenu.module.css'
import { useApplication } from '../containers/FileApplicationContainer'
import { useNavigate } from 'react-router-dom'
import { ProfileToggle } from './ProfileToggle'

const SELECTED_BUTTON_COLOR = '#fff'
const BUTTON_COLOR = '#ccc'



const FileSidebarMenu: React.FC = () => {
    const { context, send } = useApplication()
    const nav = useNavigate()

    return <div
        key={'sidebarLayout'}
        className={context.view.sidebar === SidebarMode.None
            ? styles.sidebarContainer
            : [styles.sidebarContainer, styles.sidebarContainerActive].join(' ')}
    >
        <div
            key={'sidebarButtons'}
            className={styles.sidebarButtons}
        >
            {context.sidebar?.home && <div
                key={'homeToggle'}
                className={[styles.sidebarContainerToggle, styles.sidebarContainerInfo].join(' ')}
                onClick={() => {
                    if (window.confirm('are you sure you want to leave?')) {
                        nav('/')
                    }
                }}
            >
                <BsHouseDoor
                    fill={BUTTON_COLOR} />
            </div>}
            {context.sidebar?.files ?
                <div
                    key={'filesToggle'}
                    className={styles.sidebarContainerToggle}
                    onClick={() => send({
                        type: 'toggleFolderSidebar',
                    })}
                >
                    <BsFolder fill={context.view.sidebar === SidebarMode.Folder
                        ? SELECTED_BUTTON_COLOR
                        : BUTTON_COLOR} />
                </div> : null}
            {context.errors.length ? <div
                key={'errorsToggle'}
                className={[styles.sidebarContainerToggle, styles.sidebarContainerErrors].join(' ')}
                onClick={() => send({
                    type: 'toggleErrorsSidebar',
                })}
            >
                <div className={styles.circleRedAnnotation}>{context.errors.length}</div>
                <BsSlashCircle
                    fill={context.view.sidebar === SidebarMode.Errors
                        ? SELECTED_BUTTON_COLOR
                        : BUTTON_COLOR}
                />
            </div> : null}
            {(context?.report?.length && (context.sidebar?.index || context.admin))
                ? <div
                    key={'listToggle'}
                    className={styles.sidebarContainerToggle}
                    onClick={() => send({
                        type: 'toggleListSidebar',
                    })}
                >
                    <BsCardList fill={context.view.sidebar === SidebarMode.List
                        ? SELECTED_BUTTON_COLOR
                        : BUTTON_COLOR} />
                </div>
                : null}
            {(context.sidebar?.search || context.admin) &&
                <div
                    key={'searchToggle'}
                    className={styles.sidebarContainerToggle}
                    onClick={() => send({
                        type: 'toggleSearchSidebar',
                    })}
                >
                    <BsSearch fill={context.view.sidebar === SidebarMode.Search
                        ? SELECTED_BUTTON_COLOR
                        : BUTTON_COLOR} />
                </div>}
        </div>
        <DartmouthIdent />
        <div
            key={'userToggle'}
            className={[styles.sidebarProfileToggle].join(' ')}
            onClick={() => send({
                type: 'toggleUserSidebar'
            })}
        >
            <div><ProfileToggle /></div>
        </div>
    </div>
}

export {
    FileSidebarMenu,
}

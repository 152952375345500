import { CSSProperties, FC, PropsWithChildren, useCallback, useState } from "react"
import { useApplication } from "../containers/FileApplicationContainer"
import { Modal } from "./Modal"

interface ButtonProps {
    onClick?: () => void
}

const SectionButton: FC<PropsWithChildren<ButtonProps>> = ({ children, onClick }) =>
    <button
        style={{
            fontSize: '1em',
            fontWeight: 200,
            border: 'none',
            padding: '0.5em',
            textTransform: 'uppercase',
            borderRadius: '0.2em',
        }}
        onClick={onClick}
    > {children}</button >

const SectionTitle: FC<PropsWithChildren> = ({ children }) =>
    <div style={{
        fontSize: '1.5em',
        fontWeight: 200,
    }}>{children}</div>

interface SectionProps {
    maxHeight?: string
    style?: CSSProperties
}

const Section: FC<PropsWithChildren<SectionProps>> = ({ children, style }) =>
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5em',
        ...style,
    }}>{children}</div>

const DataPathSelect: React.FC = () => {
    const { context, send } = useApplication()
    const [dataPath, setDataPath] = useState<string | undefined>(context?.dataPath)

    return <Modal>
        <Section>
            <SectionTitle>Set Data Path</SectionTitle>
            <input
                autoFocus
                autoComplete={'dartmouth--store--name'}
                type={'text'}
                value={dataPath ?? ''}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        send({
                            type: 'selectDataPath',
                            path: dataPath,
                        })
                    }
                }}
                onChange={({ target }) => {
                    const cleanPath = String(target.value).trim()
                    if (cleanPath.length < 1) {
                        setDataPath(undefined)
                        return
                    }
                    setDataPath(cleanPath)
                }}
            />
            <SectionButton
                onClick={() => send({
                    type: 'selectDataPath',
                    path: dataPath,
                })}
            >save</SectionButton>
        </Section>
    </Modal>
}

const S3PathMatch = /s3:\/\/([^/]+)\/(.+)/

const RemotePathSelect: React.FC = () => {
    const { context, send } = useApplication()
    const [[dataPath, valid], setDataPath] = useState<[string | undefined, boolean]>([context?.dataPath, S3PathMatch.test(context?.dataPath ?? '')])

    const savePath = useCallback(() => {
        if (dataPath === undefined || !valid) {
            return
        }
        const [, bucket, folder] = S3PathMatch.exec(dataPath) ?? [, undefined, undefined]
        console.log('SET STORE', bucket, folder)
        if (bucket === undefined || folder === undefined) {
            return
        }

        send({
            type: 'setStore',
            store: bucket,
            project: folder,
        })
    }, [dataPath, send, valid])

    return <Modal>
        <Section>
            <SectionTitle>Enter Remote Path</SectionTitle>
            <input
                autoFocus
                autoComplete={'dartmouth--store--name'}
                type={'text'}
                value={dataPath ?? ''}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        savePath()
                    }
                }}
                onChange={({ target }) => {
                    const cleanPath = String(target.value).trim()
                    if (cleanPath.length < 1) {
                        setDataPath([undefined, S3PathMatch.test(cleanPath)])
                        return
                    }
                    setDataPath([cleanPath, S3PathMatch.test(cleanPath)])
                }}
            />
            <div style={{
                fontSize: '0.8em',
                color: 'red',
            }}>{valid ? '' : 'invalid uri'}</div>
            <SectionButton
                onClick={() =>
                    savePath()}
            >continue</SectionButton>
        </Section>
    </Modal >
}

export {
    RemotePathSelect,
    DataPathSelect,
}

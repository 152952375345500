import { uuidv4 } from "@bbai-dartmouth/dartmouth-model-viewer-lib"
import { FileResult, StorageMode, StorageResponse, StoreStatusResponse } from "../../types"
import { ScanResult, ScanResultFailure } from '../../models/ScanResult'
import { FileExtensionMatch, WeakFileExtensionMatch } from "../fileApplicationMachine"
import { RefObject } from 'react'
import prettyBytes from 'pretty-bytes'
import { shortFilename } from "../../lib/files"
//@ts-ignore
import Papa from 'papaparse'
import { config } from "../../config"
import { ApplicationLoadFileInput } from "../fileMachine"
import { cleanupResultFile } from "../../lib/scans"
import { GroundTruthExtRecord } from "../../../models"

export interface ProgressRefs {
    progressRef: RefObject<HTMLProgressElement>
    labelRef: RefObject<HTMLDivElement>
    infoRef: RefObject<HTMLDivElement>
}

const prettySettings = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}

const extractFilename = (path: string): string => {
    try {
        const [[, filename]] = path.matchAll(/.?\/([^/]+)$/g)
        return filename
    } catch (e) {
        return path
    }
}

const xFetch = <T>(path: string, uri: string, token: string, responseType: 'blob' | 'arraybuffer' | 'json' | 'text', refs?: ProgressRefs, knownSize?: number): Promise<T> => new Promise<T>((resolve, reject) => {
    const filename = extractFilename(path)
    const request = new XMLHttpRequest()
    if (refs !== undefined) {
        const { progressRef, labelRef, infoRef } = refs
        if (infoRef.current) {
            infoRef.current.innerHTML = `<label>${shortFilename(filename)}</label><label>requesting</label>`
        }
        request.addEventListener('progress', (e) => {
            //@ts-ignore
            if (infoRef.current && infoRef.current?.dataset?.__started === 'undefined') {
                infoRef.current.dataset.__started = '1'
                infoRef.current.innerHTML = `<label>${shortFilename(filename)}</label><label>transferring</label>`
            }
            const { loaded, total } = e
            const totalBytes = total ? total : knownSize ?? 0
            if (totalBytes === 0 || totalBytes >= loaded) {
                progressRef.current?.removeAttribute('value')
                progressRef.current?.removeAttribute('max')
            } else {
                progressRef.current?.setAttribute('value', String(loaded))
                progressRef.current?.setAttribute('max', String(totalBytes))
            }
            if (labelRef.current) {
                if (total === 0 && knownSize === undefined) {
                    labelRef.current.innerHTML = `<label>&nbsp;</label><label>${prettyBytes(loaded, prettySettings)}</label>`
                } else {
                    labelRef.current.innerHTML = `<label>${prettyBytes(loaded, prettySettings)}</label><label>${prettyBytes(totalBytes, prettySettings)}</label>`
                }
            }
        })
    }
    request.addEventListener('error', (e) => {
        //@ts-ignore
        reject(e.target.responseType === 'text'
            //@ts-ignore
            ? e.target?.responseText ?? e.target?.response
            //@ts-ignore
            : e.target?.response ?? 'Unknown Error')
    })
    request.addEventListener('load', async (e) => {
        if (request.status < 400) {
            //@ts-ignore
            const response = e?.target?.response
            resolve(response)
            return
        }
        //@ts-ignore
        reject(e.target.responseType === 'text'
            //@ts-ignore
            ? e.target?.responseText ?? e.target?.response
            //@ts-ignore
            : e.target?.response ?? 'Unknown Error')
    })
    request.responseType = responseType
    request.open('get', uri)
    request.setRequestHeader('authorization', `Bearer ${token}`)
    request.send()
}).then((response) => {
    const filename = extractFilename(path)
    if (refs?.labelRef.current && refs?.infoRef.current) {
        refs.infoRef.current.dataset.__started = undefined
        refs.infoRef.current.innerHTML = `<label>${shortFilename(filename)}</label><label>processing</label>`
        refs.labelRef.current.innerHTML = `<label>&nbsp;</label><label>&nbsp;</label>`
    }
    refs?.progressRef.current?.removeAttribute('value')
    refs?.progressRef.current?.removeAttribute('max')
    return response
})

export const getAuthHeaders = (auth: string | null) => ({
    headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${getAuthToken(auth)}`
    }
})

export const getAuthToken = (auth: string | null) =>
    auth ?? 'none'

export const checkResponse = (response: Response, message: string) => {
    if (response.status === 403) {
        throw new Error('Not Authorised')
    } else if (response.status === 404) {
        throw new Error('Resource Not Found')
    } else if (response.status >= 500) {
        throw new Error('Server Failure')
    } else if (response.status >= 400) {
        throw new Error(message)
    }
    return
}

const loadGroundTruthList = async (file: FileResult, authRef: RefObject<string>) => {
    console.log('LOAD GT', file)
    if (!file.id) {
        return {
            id: 'unknown',
            groundTruths: [],
            threats: [],
        }
    }

    const groundTruths = await fetch(`${config.apiServer}/data/groundTruth?scan_id=${file.id}`, getAuthHeaders(authRef.current))
        .then((result) =>
            result.json())
        .then(async (response) => {
            if (!response.ok) {
                throw new Error('failed to load ground truth list')
            }
            return response.data.results.filter((gt: GroundTruthExtRecord & { created_at: Date }) => {
                const wasReplaced = response.data.results.find((gtc: GroundTruthExtRecord & { created_at: Date }) =>
                    gtc.threat_item_id === gt.threat_item_id &&
                    gtc.created_at && gt.created_at &&
                    gtc.created_at > gt.created_at)

                if (['revoked', 'rejected'].includes(gt.approval_status) || wasReplaced) {
                    console.debug('[api]', 'filter subsequently approved ground truth', gt)
                    return false
                }
                return true
            })
        })
    console.log('GROUND TRUTHS', file.id, groundTruths)
    const threatItems = await fetch(`${config.apiServer}/data/threatItem?scan_id=${file.id}`, getAuthHeaders(authRef.current))
        .then((result) =>
            result.json())
        .then(async (response) => {
            if (!response.ok) {
                throw new Error('failed to load threat item list')
            }
            return response.data.results.reduce((acc: any, ti: { id: string, approved_at: Date }) => {
                const wasReplaced = response.data.results.some((tic: { id: string, approved_at: Date }) =>
                    ti.id === tic.id && tic.approved_at > ti.approved_at)

                if (wasReplaced) {
                    return acc
                }
                return [
                    ...acc,
                    ti
                ]
            }, [])
        })
    console.log('THREAT ITEMS', file.id, threatItems)

    return {
        id: file.id,
        groundTruths,
        threats: threatItems
    }
}

const loadFileHandle = async (handle: FileSystemFileHandle, path: string, refs?: ProgressRefs): Promise<Blob> => {
    const filename = extractFilename(path)
    refs?.progressRef?.current?.removeAttribute('value')
    refs?.progressRef?.current?.removeAttribute('max')
    if (refs?.infoRef.current) {
        refs.infoRef.current.innerHTML = `<label>${shortFilename(filename)}</label><label>loading</label>`
    }
    const accessHandle = await handle.getFile()
    if (refs?.infoRef.current) {
        refs.infoRef.current.innerHTML = `<label>${shortFilename(filename)}</label><label>processing</label>`
    }
    return accessHandle
}

const findFileRef = (tree: FileResult[], path: string): FileResult => {
    const fileRef = tree.find((file) => file.path === path && file.type === 'file')
    if (fileRef === undefined) {
        throw new Error('file not found in tree')
    }
    return fileRef
}

const decompressArrayBuffer = (input: ArrayBuffer): Promise<Buffer> => {
    const worker = new Worker('/decompress.worker.js')
    return new Promise<Buffer>(async (resolve, reject) => {
        worker.addEventListener('message', (event) => {
            resolve(event.data)
        })
        worker.addEventListener('error', (event) => {
            reject(event)
        })
        worker.postMessage(input)
    }).finally(() =>
        worker.terminate())
}

const loadBinaryFile = async (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, refs?: ProgressRefs): Promise<Buffer | ArrayBuffer> => {
    const fileRef = findFileRef(tree, path)
    if (protocol === 's3') {
        const arrayBuffer = await xFetch<ArrayBuffer>(path, `${config.apiServer}/stores/${store}/${encodeURIComponent(fileRef.ref)}/download`, getAuthToken(auth) ?? '', 'arraybuffer', refs)
        if (path.endsWith('.br')) {

            return decompressArrayBuffer(arrayBuffer)
        }
        return arrayBuffer
    }

    const result = await loadFileHandle(fileRef.ref as never as FileSystemFileHandle, path, refs)
    const arrayBuffer = await result.arrayBuffer()
    if (path.endsWith('.br')) {
        return decompressArrayBuffer(arrayBuffer)
    }
    return arrayBuffer
}

const loadTextFile = async (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, refs?: ProgressRefs): Promise<string> => {
    const fileRef = findFileRef(tree, path)
    if (protocol === 's3') {
        return await xFetch<string>(path, `${config.apiServer}/stores/${store}/${encodeURIComponent(fileRef.ref)}/download`, getAuthToken(auth) ?? '', 'text', refs)
    }
    const result = await loadFileHandle(fileRef.ref as never as FileSystemFileHandle, path, refs)
    return await result.text()
}

const loadScanResult = async ({ authRef, dataPath, refs, store, file, report, categories, tree, handle }: ApplicationLoadFileInput): Promise<ScanResult> => {
    const protocol = file.protocol as StorageMode
    if (dataPath && protocol === 's3') {
        const resultFileName = file.name.replace(WeakFileExtensionMatch, '.json')
        const [, bucket, folder] = /s3:\/\/([^/]+)\/(.+)/.exec(dataPath) ?? [, undefined, undefined]
        if (!bucket || !folder) {
            console.log('BAD DATAPATH', dataPath)
            return {
                id: file.id ?? 'unknown',
                conclusions: []
            } as any
        }
        console.log('GOOD DATAPATH', [bucket, folder])
        const fullPath = `${folder}${resultFileName}`
        console.log('FULL DATAPATH', fullPath)

        try {
            const result = await xFetch<string>(resultFileName, `${config.apiServer}/stores/${bucket}/${encodeURIComponent(fullPath)}/download`, getAuthToken(authRef.current) ?? '', 'text', refs).then((response) => {
                return JSON.parse(response)
            })
            return cleanupResultFile(result, categories) as ScanResult
        } catch (e) {
            console.error('loadScanResult', 'failed to load from data path', e)
            return {
                id: file.id ?? 'unknown',
                conclusions: []
            } as any
        }
    }
    const resultFilePath = file.path.replace(FileExtensionMatch, '.json')

    console.debug('FILE SCAN LOAD', file, resultFilePath)
    const result = await loaders.loadJSONFile(protocol, store!, handle, resultFilePath, tree, authRef.current, refs)
        .then((rawResult) =>
            cleanupResultFile(rawResult, categories))
        .catch(() => ({
            id: file.id ?? 'unknown',
            conclusions: []
        } as ScanResultFailure))

    return result as ScanResult
}

const loadApiResult = async ({ authRef, refs, store, file, report, categories, tree, handle }: ApplicationLoadFileInput): Promise<ScanResult> => {
    const protocol = file.protocol as StorageMode
    const resultFileRef = file.ref.replace(FileExtensionMatch, '.json')
    const resultFilePath = file.path.replace(FileExtensionMatch, '.json')
    const resultFile = Object.assign({}, {
        ...file,
        path: resultFilePath,
        ref: resultFileRef,
    })
    console.debug('FILE API LOAD', file, resultFilePath)
    const { id, groundTruths, threats } = await loadGroundTruthList(file, authRef)
    const { conclusions } = await loadJSONFile(protocol, store!, handle, resultFilePath, [...tree, resultFile], authRef.current, refs)
        .then((rawResult) =>
            cleanupResultFile(rawResult, categories))
        .catch(() => ({
            id: file.id ?? 'unknown',
            conclusions: []
        } as ScanResultFailure))

    return {
        id,
        groundTruths,
        threats,
        conclusions,
    } as never as ScanResult
}

const loadCSVFile = async (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, refs?: ProgressRefs): Promise<any[]> => {
    const response = await loadTextFile(protocol, store, handle, path, tree, auth, refs)
    return Papa.parse(response, { header: true, skipEmptyLines: true }).data
}

const loadJSONFile = async (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, tree: FileResult[], auth: string | null, refs?: ProgressRefs): Promise<ScanResult> => {
    const response = await loadTextFile(protocol, store, handle, path, tree, auth, refs)
    const res = JSON.parse(response) as ScanResult
    return res
}

const selectFolder = async (protocol: StorageMode, store: string): Promise<StorageResponse | null> => {
    if (protocol === 's3') {
        return {
            projects: [
                {
                    id: '',
                    label: 'root',
                }
            ],
            partialSelection: true
        }
    }
    //@ts-ignore
    const dirHandle = await window.showDirectoryPicker()

    return {
        handle: dirHandle,
        directory: dirHandle.name,
        partialSelection: false,
    }
}

const getFolderContents = async (protocol: StorageMode, store: string, handle: FileSystemDirectoryHandle, path: string, auth: string | null, parent: string = '/'): Promise<FileResult[]> => {
    if (protocol === 's3') {
        return await fetch(`${config.apiServer}/stores/${store}/${encodeURIComponent(path)}/list`, getAuthHeaders(auth))
            .then((response) => {
                checkResponse(response, 'Failed to load directory')
                return response.json()
            })
            .then((result) =>
                result.data as FileResult[])
    }
    let tree: FileResult[] = []
    //@ts-ignore
    if (handle?.values === undefined) {
        return []
    }
    //@ts-ignore
    for await (let dirItemHandle of handle.values()) {
        if (dirItemHandle.kind === 'file') {
            const file = await dirItemHandle.getFile()
            tree.push({
                ref: dirItemHandle,
                protocol: 'local',
                type: 'file',
                id: uuidv4(),
                store: null,
                name: dirItemHandle.name.replace(FileExtensionMatch, ''),
                parent: parent,
                path: parent + file.name,
                visible: FileExtensionMatch.test(dirItemHandle.name),
                size: file.size,
                meta: {},
            })
        } else {
            const newParent = parent + dirItemHandle.name + '/'
            const children = await getFolderContents(protocol, store, dirItemHandle, path, auth, newParent)
            tree = tree.concat(children)
            tree.push({
                parent: parent,
                path: newParent,
                id: uuidv4(),
                ref: dirItemHandle,
                protocol: 'local',
                store: null,
                type: 'folder',
                name: dirItemHandle.name,
                visible: true,
                meta: {},
                children: children.filter(({ type, visible, parent }) => visible && type === 'file' && parent.startsWith(newParent)).length,
            })
        }
    }

    return tree
        .sort(({ name: a }, { name: b }) =>
            a.localeCompare(b))
        .sort(({ type: a }, { type: b }) =>
            (a === 'folder' ? 0 : 10) - (b === 'folder' ? 0 : 10))
}

const getStoreStatus = async (protocol: StorageMode, store: string, auth: string | null): Promise<StoreStatusResponse> => ({
    ok: true,
    metadata: {},
})

const loaders = {
    getFolderContents,
    getStoreStatus,
    selectFolder,
    loadBinaryFile,
    loadCSVFile,
    loadTextFile,
    loadJSONFile,
    loadScanResult,
    loadApiResult,
}

export {
    loaders,
}

import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react'
import { useMachine } from '@xstate/react'

import { useKeyHandler } from '../lib/useKeyHandler'

import remoteLayer from '../machines/layer'
import { loaders as dataLoaders } from '../machines/layer/loaders'

import { FileResult } from '../types'
import { useAuthenticationRef } from './AuthorisationContainer'
import { Helmet } from 'react-helmet'
import { ProgressBar } from '../components/ProgressBar'
import { fileMachine, FileState, } from '../machines/fileMachine'
import { useApplication } from './FileApplicationContainer'
import { ReportSchemaExt } from '../machines/fileApplicationMachine'
import ParticleSphere from '../components/ParticleSphere'
import { fileToTitle } from '../lib/files'
import { useMode } from './ModeContainer'

const FileContext = createContext<FileState | undefined>(undefined)

interface FileParameters {
    basePath: string
    file: FileResult
    tree: FileResult[]
    store?: string
    dataPath?: string
    role: 'user' | 'admin'
    mode: 'project' | 'file'
    handle?: FileSystemDirectoryHandle
    report?: ReportSchemaExt
    onSave?: (dataType: string, dataPayload: any) => void
}

const FileContainer: React.FC<PropsWithChildren<FileParameters>> = ({ children, dataPath, mode, role, onSave, file, tree, report, basePath }) => {
    const progressRef = useRef<HTMLProgressElement>(null)
    const labelRef = useRef<HTMLDivElement>(null)
    const infoRef = useRef<HTMLDivElement>(null)
    const { send: applicationSend } = useApplication()
    const roleMode = useMode()
    const authRef = useAuthenticationRef()
    const [{ value: currentState, context }, send] = useMachine(fileMachine.provide(remoteLayer(dataLoaders)).provide({
        actions: {
            emitSave: ({ event }) => {
                if (onSave === undefined) {
                    console.warn('[FileContainer]', 'onSave not provided')
                    return
                }
                onSave(event.payload.type, event.payload.data)
            },
            raiseLoaded: () => {
                applicationSend({
                    type: 'onDone'
                })
            },
            raiseError: ({ context }) => {
                applicationSend({
                    type: 'onError',
                    error: context.errors,
                })
            },
            raiseExitEditMode: () => {
                applicationSend({
                    type: 'exitEditMode',
                })
            },
            raiseEnterLockMode: () => {
                applicationSend({
                    type: 'enterLockMode',
                })
            },
            raiseSelectFile: ({ event }) => {
                applicationSend({
                    type: 'selectFile',
                    file: event?.output?.nextFile ?? event.file,
                })
            },
        }
    }), {
        input: {
            authRef,
            mode,
            role,
            protocol: file.protocol,
            basePath,
            dataPath,
            tree,
            file,
            report,
            adminMode: roleMode.mode === 'admin',
            refs: {
                progressRef,
                labelRef,
                infoRef,
            },
        }
    })

    useKeyHandler((key, { ctrlKey }) => {
        if ('viewingFile' === currentState) {
            if (['Backspace', 'Clear'].includes(key)) {
                if (context.selection?.id) {
                    if (context.groundTruths.map(({id})=>id === context.selection?.id)) {
                        // eslint-disable-next-line no-restricted-globals
                        return confirm('Revoke ground truth?') && send({
                            type: 'updateSelectionState',
                            status: 'revoked'
                        })
                    }
                    return send({
                        type: 'removeCustomConclusion',
                        id: context.selection.id
                    })
                }
                console.warn('no selection for delete')
                return
            }
            else if (key === 'ArrowUp') {
                return send({
                    type: 'nextSelection'
                })
            }
            else if (key === 'ArrowDown') {
                return send({
                    type: 'prevSelection'
                })
            }
        }

        if (key === 'Escape') {
            if (currentState.startsWith('viewing')) {
                if (context.selection !== undefined) {
                    return send({
                        type: 'setSelection'
                    })
                }
            }
        }
    })
    useEffect(()=>{
        console.log('FILE MACHINE', currentState, context)
    },[currentState, context])
    const scanLoaded = useMemo(() =>
        context.scanId && context?.data
        , [context])

    return <div>
        <Helmet>
            <title>loading | {fileToTitle(context.protocol, context.file!, context?.store ?? context.basePath)}</title>
        </Helmet>

        {!scanLoaded &&
            <ParticleSphere className={''} />}
        <ProgressBar
            visible={currentState === 'loadingFile'}
            progressRef={progressRef}
            labelRef={labelRef}
            infoRef={infoRef}
        />
        {scanLoaded && <FileContext.Provider value={{
            send,
            context,
            currentState,
        }}>{children}</FileContext.Provider>}
    </div>
}

const useFile = (): FileState => {
    const context = useContext(FileContext)
    if (context === undefined) {
        throw new Error('element is not in FileContainer context')
    }
    return context
}

export {
    FileContainer,
    useFile,
}
